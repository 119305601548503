<template>
  <div class="header">
    <BarHeader></BarHeader>
    <main class="max-w-4xl mx-auto px-4 py-12 mt-0" :class="mainClass">
      <section id="hero" class="hero-section">
        <div class="container" id="color">
          <div class="flex flex-col-reverse lg:flex-row">
            <div
              class="lg:w-6/12 pt-5 lg:pt-0 order-2 lg:order-1 lg:flex lg:flex-col lg:justify-center"
              data-aos="fade-up"
            >
              <div>
                <h1 class="mb-4 font-semibold text-2xl">
                  Arelosoft Philosophy
                </h1>
                <h2 class="text-justify">
                  Information always used by management as an important
                  strategic resource to get the best achievement. Management
                  needs the easiness of getting the accurate and up-to-date
                  information.
                </h2>
              </div>
            </div>
            <div
              class="lg:w-6/12 order-1 lg:order-2 hero-img"
              data-aos="fade-left"
            >
              <img src="@/assets/back.png" class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      <br /><br />

      <section data-aos="fade-up">
        <p class="mb-4 text-justify">
          Information always used by management as an important strategic
          resource to get the best achievement. Management needs the easiness of
          getting the accurate and up-to-date information.
        </p>
        <p class="mb-4 text-justify">
          The growth of technology is very fast. Now management can use
          information technology to organize information resources effectively
          to gain competitive and comparative aspects. Worldwide competition
          pushes the organization to keep developing a better system in order to
          take position ahead.
        </p>
        <p class="mb-4 text-justify">
          Information technology is a need for the management that will always
          grow up along with the needs. It is an opportunity for us, to develop
          more efficient and effective information technology that able to
          increase the productivity. We will always upgrade our expertise and
          take the most up-to-date information technology. It is our commitment
          to make Continuous Improvement.
        </p>
      </section>

      <section
        id="about_us"
        class="about-us"
        data-aos="fade-up"
        style="margin-top: 50px"
      >
        <div class="flex flex-col lg:flex-row items-center">
          <img
            src="@/assets/tentang.png"
            alt="Gambar Konten"
            class="w-full lg:w-5/12 mb-4 lg:mb-0 rounded-lg"
            style="margin-right: 20px"
          />
          <div class="lg:w-7/12">
            <h2
              class="text-2xl font-bold mb-4 text-center lg:text-left"
              style="color: #002d66"
            >
              About Us
            </h2>
          </div>
        </div>

        <ul>
          <li data-aos="fade-up" data-aos-delay="100">
            <div>
              <h5 style="color: #002d66" class="text-lg font-bold">
                <i
                  class="bi bi-lightbulb"
                  style="
                    float: left;
                    margin-right: 10px;
                    font-size: 32px;
                    margin-top: 5px;
                  "
                ></i>
                <span style="padding-left: 20px">Our Vision</span>
              </h5>
              <p style="margin-left: 60px">
                Becoming a successful information technology company and has a
                significant role in Indonesia
              </p>
            </div>
            <br />
          </li>

          <li data-aos="fade-up" data-aos-delay="200">
            <div>
              <h5 style="color: #002d66" class="text-lg font-bold">
                <i
                  class="bi bi-eye"
                  style="
                    float: left;
                    margin-right: 10px;
                    font-size: 32px;
                    margin-top: 5px;
                  "
                ></i>
                <span style="padding-left: 20px">Our Mission</span>
              </h5>
              <p style="margin-left: 60px">
                Arelosoft is positioned to take advantage of the significant
                market opportunities available in the customized information
                technology field and to participate in national development
                through successful investments in national information
                technology. We would like to give our customers all of our
                knowledge and expertise by the high quality and valuable system.
                We provide organizations with highly skilled information
                technology professionals, which enable clients to successfully
                achieve their business objectives by using appropriate
                information technology solutions.
              </p>
            </div>
            <br />
          </li>
          <li data-aos="fade-up" data-aos-delay="300">
            <div>
              <h5 style="color: #002d66" class="text-lg font-bold">
                <i
                  class="bi bi-file-text"
                  style="
                    float: left;
                    margin-right: 10px;
                    font-size: 32px;
                    margin-top: 5px;
                  "
                ></i>
                <span style="padding-left: 20px">Our Objectives</span>
              </h5>
              <p style="margin-left: 60px">
                1. To manage the company’s portfolio effectively and efficiently
                from the existing reserves<br />
                2. To utilize all information technology reserves optimally<br />
                3. To create a profitable business by performing great
                information technology innovations<br />
                4. To maintain a strong financial basis to support company’s
                expansion and operation
              </p>
            </div>
            <br />
          </li>
          <li data-aos="fade-up" data-aos-delay="300">
            <div>
              <h5 style="color: #002d66" class="text-lg font-bold">
                <i
                  class="bi bi-gear"
                  style="
                    float: left;
                    margin-right: 10px;
                    font-size: 32px;
                    margin-top: 5px;
                  "
                ></i>
                <span style="padding-left: 20px">Our Service</span>
              </h5>
              <p style="margin-left: 60px">
                Based on our experience in developing, designing, installing and
                maintaining various systems and equipments in information
                technology, we offer high quality taylor made software
                development services.
              </p>
            </div>
            <br />
          </li>
          <li data-aos="fade-up" data-aos-delay="300">
            <div>
              <h5 style="color: #002d66" class="text-lg font-bold">
                <i
                  class="bi bi-person"
                  style="
                    float: left;
                    margin-right: 10px;
                    font-size: 32px;
                    margin-top: 5px;
                  "
                ></i>
                <span style="padding-left: 20px">Our Resources</span>
              </h5>
              <p style="margin-left: 60px">
                Depok city has a science and technology atmosphere, and has many
                competent human resources in information technology from
                outstanding universities in Indonesia. It is one of the adaptive
                places of the growth of information technology, and give great
                respect of it.
              </p>
            </div>
          </li>
        </ul>
      </section>

      <section id="contact_us" class="contact-us" data-aos="fade-up">
        <div class="flex flex-col lg:flex-row items-center">
          <img
            src="@/assets/kontak.png"
            alt="Gambar Konten"
            class="w-full lg:w-5/12 mb-4 lg:mb-0 rounded-lg"
            style="margin-right: 20px"
          />
          <div class="lg:w-7/12">
            <h2
              class="text-2xl font-bold mb-4 text-center lg:text-left"
              style="color: #002d66"
            >
              Contact
            </h2>
          </div>
        </div>

        <p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.0968466070794!2d106.83267157021017!3d-6.381499648269404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ebf5943f4e39%3A0xe984177b0d7890b3!2sJl.%20Pesona%20Khayangan%20Blok%20Dy%20No.5!5e0!3m2!1sen!2sid!4v1704181479238!5m2!1sen!2sid"
            width="100%"
            height="300"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </p>
        <div class="container">
          <div class="row gy-4 mt-4">
            <div class="col-lg-4">
              <div class="info-item d-flex">
                <i class="bi bi-geo-alt"></i>
                <div class="ms-3 align-items-center">
                  <h5 style="color: #002d66" class="text-lg font-bold">
                    Location:
                  </h5>
                  <p>
                    Warehouse:<br />
                    Pesona Khayangan Blok EM No.5 Margonda, Depok, Jawa Barat,
                    16411
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="info-item d-flex">
                <i class="bi bi-envelope flex-shrink-0"></i>
                <div class="ms-3 align-items-center">
                  <h5 style="color: #002d66" class="text-lg font-bold">
                    Email:
                  </h5>
                  <p>info@arelosoft.com</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="info-item d-flex">
                <i class="bi bi-phone"></i>
                <div class="ms-3 align-items-center">
                  <div>
                    <h5 style="color: #002d66" class="text-lg font-bold">
                      Phone/Fax:
                    </h5>
                    <p>6221 - 7782 6903</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <BarFooter></BarFooter>
  </div>
</template>

<script>
import BarHeader from "./components/BarHeader.vue";
import BarFooter from "./components/BarFooter.vue";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "App",
  components: {
    BarHeader,
    BarFooter,
  },
  data() {
    return {
      currentImage: 0,
      images: [
        "https://via.placeholder.com/640x360/0000FF/ffffff?text=Image+1",
        "https://via.placeholder.com/640x360/00FF00/ffffff?text=Image+2",
        "https://via.placeholder.com/640x360/FF0000/ffffff?text=Image+3",
      ],
      isIconClicked: false,
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    AOS.init({
      duration: 800,
      once: true,
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    mainClass() {
      if (this.windowWidth < 768) {
        return "mobile-class";
      } else if (this.windowWidth < 1024) {
        return "tablet-class";
      } else {
        return "desktop-class";
      }
    },
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    prevImage() {
      this.currentImage--;
    },
    nextImage() {
      this.currentImage++;
    },
    toggleIconColor() {
      this.isIconClicked = !this.isIconClicked;
    },
    toggleIconSize(target) {
      target.classList.toggle("large");

      if (target.classList.contains("large")) {
        this.isIconClicked = true;
      } else {
        this.isIconClicked = false;
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#hero {
  background-image: url("@/assets/bg.jpeg");
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  margin-top: 0;
}

.hero-section {
  padding-top: 100px;
  padding-bottom: 100px;
  margin-top: 0;
}

#color {
  color: white;
}

@keyframes shadowAnimation {
  0% {
    text-shadow: none;
  }
  50% {
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
  100% {
    text-shadow: none;
  }
}

header {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #ffffff;
}

.about-us {
  position: relative;
  margin-top: 20px;
}

.about-us h2 {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #002d66;
  position: relative;
  z-index: 1;
  animation: shadowAnimation 2s infinite;
}

.about-us h2::before {
  content: "About Us";
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  font-size: 4rem;
  font-weight: bold;
  opacity: 0.2;
  color: #002d66;
}

.contact-us {
  position: relative;
  margin-top: 50px;
}

.contact-us h2 {
  font-size: 4rem;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 3rem;
  color: #002d66;
  position: relative;
  z-index: 1;
  animation: shadowAnimation 2s infinite;
}

.contact-us h2::before {
  content: "Contact";
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  font-size: 4rem;
  font-weight: bold;
  opacity: 0.2;
  color: #002d66;
}

.circle {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.circle i.large {
  font-size: 30px;
}

.circle.large {
  width: 70px;
  height: 70px;
}

.circle:hover {
  background-color: #f0f0f0;
}

.active {
  color: #002d66;
}
</style>
