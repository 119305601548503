<template>
  <footer
    id="footer"
    class="color py-4 px-6 text-white flex flex-col items-start justify-between"
  >
    <div class="links-container flex flex-col justify-start items-start">
      <div class="flex flex-col mb-4">
        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-5 col-md-12 footer-info">
              <span class="text-lg font-bold">Arelosoft</span><br />
              <p class="mt-2">
                Information always used by management as an important strategic
                resource to get the best achievement. Management needs the
                easiness of getting the accurate and up-to-date information.
              </p>
            </div>
            <div class="col-lg-2 col-6 footer-links">
              <span class="text-lg font-bold">Useful Links</span> <br />
              <br />
              <div class="vertical-links">
      <a
        class="mr-6 hover:text-gray-400"
        v-for="link in links"
        :href="link.section"
        :key="link.id"
        v-text="link.name"
      ></a>
              </div>
            </div>
            <div
              class="col-lg-3 col-md-12 footer-contact text-md-start"
            >
              <span class="text-lg font-bold">Contact</span>
              <br /><br />
              <p>
                WareHouse : <br />
                Pesona Khayangan Blok EM No.5 Margonda, Depok, Jawa Barat, 16411<br/><br/>
                Phone/Fax : 6221 - 7782 6903<br /><br/>
                Email : info@arelosoft.com<br /><br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br /><br />
    <div
      class="copy-right text-white mt-auto text-center w-full text-sm font-medium"
    >
      <div class="mx-auto">
        &copy; {{ year }} PT. ARELOSOFT INOVASI INDONESIA
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      links: [
        { id: 1, name: "Home", section: "#" },
        { id: 2, name: "About Us", section: "#about_us" },
        { id: 3, name: "Contact", section: "#contact_us" },
      ],
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.copy-right {
  color: white;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.vertical-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.color {
  background-color: #002d66;
}
</style>
