<template>
  <header
    class="color py-2 px-4 text-white flex justify-between items-center fixed top-0 left-0 w-full"
    v-bind:class="{ 'shadow-md': hasScrolled }"
  >
    <div class="flex items-center">
      <img
        src="@/assets/arelo.png"
        alt="Arelosoft Logo"
        class="mr-2 h-12 w-12"
      />
      <h1 class="font-bold text-2xl">Arelosoft Inovasi Indonesia</h1>
    </div>

    <!-- Mobile Menu Button -->
    <button class="md:hidden focus:outline-none" @click="toggleMobileMenu">
      <i class="bi bi-list text-3xl"></i>
    </button>

    <!-- Mobile Menu -->
    <nav
      class="md:hidden absolute top-16 left-0 w-full py-2 px-4 mt-2"
      v-show="isMobileMenuOpen"
      style="background-color: #002d66"
    >
      <a
        class="block mb-2 text-white hover:text-gray-400 text-right"
        v-for="link in links"
        :href="link.section"
        :key="link.id"
        v-text="link.name"
      ></a>
    </nav>

    <!-- Original Desktop Menu -->
    <nav class="hidden md:flex">
      <a
        class="mr-6 hover:text-gray-400"
        v-for="link in links"
        :href="link.section"
        :key="link.id"
        v-text="link.name"
      ></a>
    </nav>
  </header>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      hasScrolled: false,
      isMobileMenuOpen: false,
      links: [
        { id: 1, name: "Home", section: "#" },
        { id: 2, name: "About Us", section: "#about_us" },
        { id: 3, name: "Contact", section: "#contact_us" },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.hasScrolled = window.scrollY > 0;
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
  },
};
</script>

<style scoped>
.color {
  background-color: #002d66;
}
</style>
