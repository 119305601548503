import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/tailwind.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import { BootstrapIconsPlugin, BIcon } from "bootstrap-icons-vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapIconsPlugin);

Vue.component("BIcon", BIcon);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
